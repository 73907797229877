import React, { useEffect, useState } from "react";
import Header from "./Header";
import "../cssFiles/CollegePredictor.css";
import { useLocation, useNavigate } from "react-router-dom";
import { Select } from "antd";
import { Option } from "antd/es/mentions";
import comingSoon from "../images/coming_soon.gif";

function CollegePredictor() {
  const navigate = useNavigate();
  const location = useLocation();
  const [formInput, setFormInput] = useState<any>({
    user_name:"",
    crl_rank: "",
    category: "",
    category_rank: "",
    gender: "",
    homestate: "",
    table_name: location.state.predictorName ?? "csab",
  });
  const [filterExists, setFilterExists] = useState<any>(
    location.state.predictorName
  );
  const [dropDownData, setDropdownData] = useState<any>({});
  const [activateBanner, setActivateBanner] = useState<boolean>(false);

  function validate() {
    if (formInput?.crl_rank === "" || formInput?.crl_rank.trim().length === 0) {
      setActivateBanner(true);
    } else if (formInput?.category === "") {
      setActivateBanner(true);
    } else if (showCategoryRankField() && formInput?.category_rank === "") {
      setActivateBanner(true);
    } else if (formInput?.gender === "") {
      setActivateBanner(true);
    } else {
      navigate("/collegeList", {
        state: {
          formDetails: { ...formInput },
        },
      });
    }
  }

  function fieldUpdated(event: any, fieldName: any) {
    if (activateBanner) {
      setActivateBanner(false);
    }
    let tempFormDetails: any = { ...formInput };
    if (
      fieldName === "category" ||
      fieldName === "gender" ||
      fieldName === "homestate"
    ) {
      tempFormDetails[fieldName] = event;
    } else {
      tempFormDetails[fieldName] = event.target.value;
    }
    setFormInput({ ...tempFormDetails });
  }

  useEffect(() => {
    // let userDetail: any = localStorage.getItem("user_email");
    // if (userDetail === null || userDetail?.length === 0) {
    //   navigate("/login");
    // }
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("access-control-allow-origin", "*");
    myHeaders.append("access-control-allow-methods", "POST");
    myHeaders.append("access-control-allow-headers", "Content-Type");
    fetch("api/filterKeys", {
      method: "POST",
      body: JSON.stringify({
        table_name: location.state.predictorName ?? "josaa",
      }),
      headers: myHeaders,
      mode: "cors",
    })
      .then((res: any) => res.json())
      .then((event: any) => {
        setDropdownData({
          Category: event?.Category ? event?.Category.sort() : [],
          Gender: event?.Gender ? event?.Gender.sort() : [],
          Quota: event?.Quota ? event?.Quota.sort() : [],
        });
      });
  }, []);

  function getFilterTitle() {
    let selectedFilter: string = location.state.predictorName ?? "";
    switch (selectedFilter) {
      case "josaa":
        return "JoSAA Counselling";
      case "csab":
        return "CSAB Counselling";
      case "jac_delhi":
        return "JAC Delhi Counselling";
      case "jac_chandigarh":
        return "JAC Chandigarh Counselling";
      case "uptac_upsee":
        return "UPCET / UPSEE Counselling";
      case "mpdte":
        return "MPDTE Counselling";
      case "ikgptu":
        return "PTU Counselling";
      case "thapar":
        return "Thapar (TIET) Counselling";
      case "hbtu_kanpur":
        return "HBTU (Kanpur) Counselling";
      case "jaypee_noida":
        return "Jaypee, Noida Counselling";
      case "iit_database":
        return "IIT";
      default:
        return "JoSAA Counselling";
    }
  }

  function showCategoryRankField() {
    if (formInput?.category != "" && formInput?.category == "OPEN") {
      return false;
    } else if (formInput.category != "" && formInput?.category === "GENERAL") {
      return false;
    } else if (formInput.category == "") {
      return false;
    } else {
      return true;
    }
  }

  return (
    <React.Fragment>
      <Header />

      {filterExists === "" ? (
        <div className="container">
          <div className="row justify-content-center rounded">
            <img src={comingSoon} className="comingSoonImage" />
          </div>
        </div>
      ) : (
        <div className="container">
          <h3>
            {" "}
            <span>{getFilterTitle()} College Predictor </span>
          </h3>
          <p className="detailsLine">
            {location.state.predictorName === "iit_database"
              ? "Enter your JEE Advanced Result Details"
              : "Enter JEE Main Paper 1 Exam Details"}
          </p>
          {activateBanner && (
            <div className="text text-danger text-center">
              <h5>** Please fill all the details ** </h5>
            </div>
          )}
          {/* form details start here */}
          <div className="row justify-content-center">
              <div className="col-lg-6 col-md-10 col-sm-12 formDiv">
              <div className="input-group mb-3">
                <span className="input-group-text" id="crl_rank">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="32"
                    height="32"
                    fill="currentColor"
                    className="bi bi-person-circle"
                    viewBox="0 0 16 16"
                  >
                    <path d="M11 6a3 3 0 1 1-6 0 3 3 0 0 1 6 0z" />
                    <path
                      fill-rule="evenodd"
                      d="M0 8a8 8 0 1 1 16 0A8 8 0 0 1 0 8zm8-7a7 7 0 0 0-5.468 11.37C3.242 11.226 4.805 10 8 10s4.757 1.225 5.468 2.37A7 7 0 0 0 8 1z"
                    />
                  </svg>
                </span>
                <input
                  type="text"
                  value={formInput.user_name}
                  onChange={(event) => {
                    fieldUpdated(event, "user_name");
                  }}
                  className="form-control"
                  placeholder="Enter User Name"
                  aria-label="user_name"
                  aria-describedby="user_name"
                />
              </div>
              <div className="input-group mb-3">
                <span className="input-group-text" id="crl_rank">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="32"
                    height="32"
                    fill="currentColor"
                    className="bi bi-person-circle"
                    viewBox="0 0 16 16"
                  >
                    <path d="M11 6a3 3 0 1 1-6 0 3 3 0 0 1 6 0z" />
                    <path
                      fill-rule="evenodd"
                      d="M0 8a8 8 0 1 1 16 0A8 8 0 0 1 0 8zm8-7a7 7 0 0 0-5.468 11.37C3.242 11.226 4.805 10 8 10s4.757 1.225 5.468 2.37A7 7 0 0 0 8 1z"
                    />
                  </svg>
                </span>
                <input
                  type="text"
                  value={formInput.crl_rank}
                  onChange={(event) => {
                    fieldUpdated(event, "crl_rank");
                  }}
                  className="form-control"
                  placeholder="Enter your CRL Rank"
                  aria-label="crl_rank"
                  aria-describedby="crl_rank"
                />
              </div>

              <div className="input-group mb-3">
                <span className="input-group-text" id="category">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="32"
                    height="32"
                    fill="currentColor"
                    className="bi bi-person-circle"
                    viewBox="0 0 16 16"
                  >
                    <path d="M11 6a3 3 0 1 1-6 0 3 3 0 0 1 6 0z" />
                    <path
                      fill-rule="evenodd"
                      d="M0 8a8 8 0 1 1 16 0A8 8 0 0 1 0 8zm8-7a7 7 0 0 0-5.468 11.37C3.242 11.226 4.805 10 8 10s4.757 1.225 5.468 2.37A7 7 0 0 0 8 1z"
                    />
                  </svg>
                </span>
                <Select
                  className="form-select"
                  defaultValue={dropDownData?.Category?.[0]}
                  onChange={(event: any) => {
                    fieldUpdated(event, "category");
                  }}
                  value={formInput?.category}
                >
                  <Option key={""} value={""}>
                    Select your Category
                  </Option>
                  {dropDownData?.Category?.map((option: any) => (
                    <Option key={option} value={option}>
                      {option}
                    </Option>
                  ))}
                </Select>
              </div>

              {showCategoryRankField() && (
                <div className="input-group mb-3">
                  <span className="input-group-text" id="crl_rank">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="32"
                      height="32"
                      fill="currentColor"
                      className="bi bi-person-circle"
                      viewBox="0 0 16 16"
                    >
                      <path d="M11 6a3 3 0 1 1-6 0 3 3 0 0 1 6 0z" />
                      <path
                        fill-rule="evenodd"
                        d="M0 8a8 8 0 1 1 16 0A8 8 0 0 1 0 8zm8-7a7 7 0 0 0-5.468 11.37C3.242 11.226 4.805 10 8 10s4.757 1.225 5.468 2.37A7 7 0 0 0 8 1z"
                      />
                    </svg>
                  </span>
                  <input
                    type="text"
                    value={formInput.category_rank}
                    onChange={(event) => {
                      fieldUpdated(event, "category_rank");
                    }}
                    className="form-control"
                    placeholder="Enter your Category Rank"
                    aria-label="crl_rank"
                    aria-describedby="crl_rank"
                  />
                </div>
              )}
              <div className="input-group mb-3">
                <span className="input-group-text" id="crl_rank">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="32"
                    height="32"
                    fill="currentColor"
                    className="bi bi-person-circle"
                    viewBox="0 0 16 16"
                  >
                    <path d="M11 6a3 3 0 1 1-6 0 3 3 0 0 1 6 0z" />
                    <path
                      fill-rule="evenodd"
                      d="M0 8a8 8 0 1 1 16 0A8 8 0 0 1 0 8zm8-7a7 7 0 0 0-5.468 11.37C3.242 11.226 4.805 10 8 10s4.757 1.225 5.468 2.37A7 7 0 0 0 8 1z"
                    />
                  </svg>
                </span>
                <Select
                  placeholder="Select you"
                  className="form-select"
                  onChange={(event: any) => {
                    fieldUpdated(event, "gender");
                  }}
                  value={formInput?.gender}
                >
                  <Option key={""} value={""}>
                    Select your gender
                  </Option>
                  {dropDownData?.Gender?.map((option: any) => (
                    <Option key={option} value={option}>
                      {option}
                    </Option>
                  ))}
                </Select>
              </div>

              {location.state.predictorName !== "iit_database" && (
                <div className="input-group mb-3">
                  <span className="input-group-text" id="crl_rank">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="32"
                      height="32"
                      fill="currentColor"
                      className="bi bi-person-circle"
                      viewBox="0 0 16 16"
                    >
                      <path d="M11 6a3 3 0 1 1-6 0 3 3 0 0 1 6 0z" />
                      <path
                        fill-rule="evenodd"
                        d="M0 8a8 8 0 1 1 16 0A8 8 0 0 1 0 8zm8-7a7 7 0 0 0-5.468 11.37C3.242 11.226 4.805 10 8 10s4.757 1.225 5.468 2.37A7 7 0 0 0 8 1z"
                      />
                    </svg>
                  </span>
                  {location.state.predictorName === "josaa" ||
                  location.state.predictorName === "csab" ? (
                    <Select
                      placeholder="Select your Homestate"
                      className="form-select"
                      onChange={(event: any) => {
                        fieldUpdated(event, "homestate");
                      }}
                      value={formInput?.homestate}
                    >
                      <Option key={""} value={""}>
                        Select your Homestate
                      </Option>
                      {dropDownData?.Quota?.map((option: any) => (
                        <Option key={option} value={option}>
                          {option}
                        </Option>
                      ))}
                    </Select>
                  ) : (
                    <Select
                      placeholder="Select your Quota"
                      className="form-select"
                      onChange={(event: any) => {
                        fieldUpdated(event, "homestate");
                      }}
                      value={formInput?.homestate}
                    >
                      <Option key={""} value={""}>
                        Select your Quota
                      </Option>
                      <Option key={"AI"} value={"AI"}>
                        AI
                      </Option>
                      <Option key={"HS"} value={"HS"}>
                        HS
                      </Option>
                    </Select>
                  )}
                </div>
              )}

              {/* group of predict button */}
              <div className="input-group mb-3 actionBtn">
                <button
                  className="btn btn-primary w-50 mt-3 predict"
                  onClick={() => {
                    validate();
                  }}
                >
                  PREDICT MY COLLEGES
                </button>

                <button
                  className="btn btn-danger w-50 mt-3 back"
                  onClick={() => {
                    navigate("/selectCounciling");
                  }}
                >
                  Go Back
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </React.Fragment>
  );
}

export default CollegePredictor;
