import React, { useEffect, useRef, useState } from "react";
import { Table } from "antd";
import { useLocation, useNavigate } from "react-router-dom";
import Header from "./Header";
import "../cssFiles/CollegeList.css";

function CollegeList() {
  const [data, setData] = useState<any>([]);
  const [pdfData, setPdfData] = useState<any>({ user: {},jsonData: [{}],fileName:"default_file"});

  const location = useLocation();
  const navigate = useNavigate();
  const requiredData = location.state.formDetails;
  const columns = [
    {
      title: "Institute",
      dataIndex: "Institute",
    },
    {
      title: "Academic Program Name",
      dataIndex: "Academic_Program_Name",
    },
    {
      title: "Average Placement Per Year",
      dataIndex: "Average_Placement_Per_Year",
    },
    {
      title: "Highest Placement Per Year",
      dataIndex: "Highest_Placement_Per_Year",
    },
    {
      title: "College Fees Per Year",
      dataIndex: "College_Fees_Per_Year",
    },
    {
      title: "Hostel Fees Per Year",
      dataIndex: "Hostel_Fees_Per_Year",
    },
  ];

  useEffect(() => {
    // let userDetail: any = localStorage.getItem("user_email");
    // if (userDetail === null || userDetail?.length === 0) {
    //   navigate("/login");
    // }
    getData();
  }, []);

  async function getData() {
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("access-control-allow-origin", "*");
    myHeaders.append("access-control-allow-methods", "POST");
    myHeaders.append("access-control-allow-headers", "Content-Type");

    let formData: any = JSON.stringify({
      table_name: requiredData?.table_name,
      quota: requiredData?.homestate,
      category: requiredData?.category,
      gender: requiredData?.gender,
      closing_rank: requiredData?.crl_rank,
      category_rank: requiredData?.category_rank ?? false
    });
    fetch("api/predictor", {
      method: "POST",
      body: formData,
      headers: myHeaders,
      mode: "cors",
    })
      .then((res: any) => res.json())
      .then((event: any) => {
        let temp: any = [];
        let pdfData = {
          user: {},
          jsonData: [{}],
          fileName:  requiredData?.user_name + "_" + requiredData?.table_name
        };
        pdfData.jsonData = event;
        pdfData.user = {
          name: requiredData?.user_name,
          quota: requiredData?.homestate,
          rank: requiredData?.crl_rank,
          category: requiredData?.category,
          category_rank: requiredData?.category_rank ?? "NA"
        };
        setPdfData(pdfData);

        event?.map((counter: any) => {
          let kurkurey: any = {
            Institute: counter?.INSTITUTE ?? "NA",
            Academic_Program_Name: counter?.["Academic Program"] ?? "NA",
            Average_Placement_Per_Year: counter?.["Average Placement"] ?? "NA",
            Highest_Placement_Per_Year: counter?.["Highest Placement"] ?? "NA",
            College_Fees_Per_Year: counter?.["Colleges Fee/Year"] ?? "NA",
            Hostel_Fees_Per_Year: counter?.["Hostel Fees/Year"] ?? "NA",
          };
          temp.push(kurkurey);
        });
        setData([...temp]);
      });
  }
  function download(blob:any, filename:any) {
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.style.display = 'none';
    a.href = url;
    // the filename you want
    a.download = filename;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    window.URL.revokeObjectURL(url);
  }

  const generatePDf = async () => {
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("access-control-allow-origin", "*");
    myHeaders.append("access-control-allow-methods", "POST");
    myHeaders.append("access-control-allow-headers", "Content-Type");
    // let pdfData = {
    //   user: {},
    //   jsonData: [{}],
    //   fileName:"default_file"
    // };
    // const userData = {
    //   name : "Vikas",
    //   quota : "AI",
    //   rank : "123123",
    //   category : "OBC",
    //   category_rank : "123"
    // }
    // pdfData.user = userData;
    // pdfData.jsonData = data;
    fetch("api/generatePdf", {
      method: "POST",
      body: JSON.stringify(pdfData),
      headers: myHeaders,
      mode: "cors",
    }).then(res => res.blob()).then(blob => download(blob,pdfData?.fileName))
  }



  return (
    <React.Fragment>
      <Header />
      <button onClick={generatePDf}>Generate PDF</button>
      <div className="container">
        <div  className="tableListClass">
          {data?.length > 0 && (
            <Table
              dataSource={data}
              columns={columns}
              size="middle"
              scroll={{ x: 700 }}
              sticky
            />
          )}
        </div>
      </div>
    </React.Fragment>
  );
}

export default CollegeList;
