import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

function Panel(){
    const [data, setData] = useState<any>();
    const navigate = useNavigate();

    useEffect(()=>{
        navigate('./selectCounciling')
    },[]);
    return <React.Fragment>

    </React.Fragment>
}

export default Panel