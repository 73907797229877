import React from "react";
import image from "../images/only_logo.png";
import "../cssFiles/Headers.css";

function Header() {
  return (
    <React.Fragment>
      <div className="container mainHeader">
        <div className="row justify-content-center">
          <div className="col-lg-4 col-md-12 col-sm-12 d-flex justify-content-center">
            <img src={image} className="logoSize" />
            <span className="logoText">
              areer Margdarshan
            </span>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}

export default Header;
