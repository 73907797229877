import React from "react";
import { Routes, Route } from "react-router-dom";
import "./App.css";
import PageNotFound from "./components/PageNotFound";
import Login from "./components/Login";
import CollegePredictor from "./components/FilterOptions/CollegePredictor";
import FilterType from "./components/FilterOptions/FilterType";
import Panel from "./components/Panel";
import CollegeList from "./components/FilterOptions/CollegeList";

function App() {
  return (
    <React.Fragment>
      <Routes>
        <Route path="/" element={<Panel />} />
        {/* <Route path="/login" element={<Login />} /> */}
        <Route path="/selectCounciling" element={<FilterType />} />
        <Route path="/collegepredictor" element={<CollegePredictor />} />
        <Route path="/collegeList" element={<CollegeList />} />
        <Route path="/*" element={<PageNotFound />} />
      </Routes>
    </React.Fragment>
  );
}

export default App;
