import React from "react";
import Header from "./FilterOptions/Header";
import not_found from './images/404_not_found.gif';
import { useNavigate } from "react-router-dom";

function PageNotFound(){
    const navigate = useNavigate();
    return <React.Fragment>
        <Header />
        <div className="App mt-5">
            <img src={not_found} height={"300px"} width={"auto"} style={{borderRadius:'50%'}}/>
            <div className="container w-50 mt-4">

            <p className="alert">Page Not Found. Please Click here to <span className="btn btn-primary" onClick={()=>{navigate("/login")}}>login</span> </p>
            </div>
        </div>
    </React.Fragment>
}

export default PageNotFound