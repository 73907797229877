import React, { useEffect } from "react";
import "../cssFiles/Filter.css";
import logo from "../images/only_logo.png";
import Header from "./Header";
import { redirect, useNavigate } from "react-router-dom";
function FilterType() {
  const navigate = useNavigate();

  // useEffect(() => {
  //   let userDetail: any = localStorage.getItem("user_email");
  //   if (userDetail === null || userDetail?.length === 0) {
  //     navigate("/login");
  //   }
  // }, []);
  return (
    <React.Fragment>
      <Header />
      <div className="container-fluid filterTypePage">
        <div className="container upperDiv">
          {/* grouping of button starts here */}
          <div className="row justify-content-md-center">
            <div className="col-lg-12 col-sm-12 col-md-12">
              <p className="tagLine">
                Predict the best possible
                <span>COLLEGES on your JEE Rank</span>
              </p>
            </div>
            <div className="col-lg-8 col-md-12 col-sm-12 predictor">
              <div className="d-flex justify-content-between">
                <span className="predictorPara">
                  JoSAA Counselling College Predictor
                </span>
                <button
                  className="predictorBtn"
                  onClick={() => {
                    navigate("/collegepredictor", {
                      state: { predictorName: "josaa" },
                    });
                  }}
                >
                  PREDICT COLLEGES
                </button>
              </div>
            </div>

            <div className="col-lg-8 col-md-12 col-sm-12 predictor">
              <div className="d-flex justify-content-between">
                <span className="predictorPara">
                  CSAB Counselling College Predictor
                </span>
                <button
                  className="predictorBtn"
                  onClick={() => {
                    navigate("/collegepredictor", {
                      state: { predictorName: "csab" },
                    });
                  }}
                >
                  PREDICT COLLEGES
                </button>
              </div>
            </div>

            <div className="col-lg-8 col-md-12 col-sm-12 predictor">
              <div className="d-flex justify-content-between">
                <span className="predictorPara">
                  JAC Delhi Counselling College Predictor
                </span>
                <button
                  className="predictorBtn"
                  onClick={() => {
                    navigate("/collegepredictor", {
                      state: { predictorName: "jac_delhi" },
                    });
                  }}
                >
                  PREDICT COLLEGES
                </button>
              </div>
            </div>

            <div className="col-lg-8 col-md-12 col-sm-12 predictor">
              <div className="d-flex justify-content-between">
                <span className="predictorPara">
                  JAC Chandigarh Counselling College Predictor
                </span>
                <button
                  className="predictorBtn"
                  onClick={() => {
                    navigate("/collegepredictor", {
                      state: { predictorName: "jac_chandigarh" },
                    });
                  }}
                >
                  PREDICT COLLEGES
                </button>
              </div>
            </div>

            <div className="col-lg-8 col-md-12 col-sm-12 predictor">
              <div className="d-flex justify-content-between">
                <span className="predictorPara">
                  UPCET / UPSEE Counselling College Predictor
                </span>
                <button
                  className="predictorBtn"
                  onClick={() => {
                    navigate("/collegepredictor", {
                      state: { predictorName: "uptac_upsee" },
                    });
                  }}
                >
                  PREDICT COLLEGES
                </button>
              </div>
            </div>

            <div className="col-lg-8 col-md-12 col-sm-12 predictor">
              <div className="d-flex justify-content-between">
                <span className="predictorPara">
                  MPDTE Counselling College Predictor
                </span>
                <button
                  className="predictorBtn"
                  onClick={() => {
                    navigate("/collegepredictor", {
                      state: { predictorName: "mpdte" },
                    });
                  }}
                >
                  PREDICT COLLEGES
                </button>
              </div>
            </div>

            <div className="col-lg-8 col-md-12 col-sm-12 predictor">
              <div className="d-flex justify-content-between">
                <span className="predictorPara">
                  PTU Counselling College Predictor
                </span>
                <button
                  className="predictorBtn"
                  onClick={() => {
                    navigate("/collegepredictor", {
                      state: { predictorName: "ikgptu" },
                    });
                  }}
                >
                  PREDICT COLLEGES
                </button>
              </div>
            </div>

            <div className="col-lg-8 col-md-12 col-sm-12 predictor">
              <div className="d-flex justify-content-between">
                <span className="predictorPara">Thapar (TIET) Predictor</span>
                <button
                  className="predictorBtn"
                  onClick={() => {
                    navigate("/collegepredictor", {
                      state: { predictorName: "thapar" },
                    });
                  }}
                >
                  PREDICT COLLEGES
                </button>
              </div>
            </div>

            <div className="col-lg-8 col-md-12 col-sm-12 predictor">
              <div className="d-flex justify-content-between">
                <span className="predictorPara">HBTU (Kanpur) Predictor</span>
                <button
                  className="predictorBtn"
                  onClick={() => {
                    navigate("/collegepredictor", {
                      state: { predictorName: "hbtu_kanpur" },
                    });
                  }}
                >
                  PREDICT COLLEGES
                </button>
              </div>
            </div>

            <div className="col-lg-8 col-md-12 col-sm-12 predictor">
              <div className="d-flex justify-content-between">
                <span className="predictorPara">
                  Jaypee, Noida Counselling - Predictor
                </span>
                <button
                  className="predictorBtn"
                  onClick={() => {
                    navigate("/collegepredictor", {
                      state: { predictorName: "jaypee_noida" },
                    });
                  }}
                >
                  PREDICT COLLEGES
                </button>
              </div>
            </div>

            {/* <div className="col-lg-8 col-md-12 col-sm-12 predictor">
              <div className="d-flex justify-content-between">
                <span className="predictorPara">
                  IPU Counselling College Predictor
                </span>
                <button
                  className="predictorBtn"
                  onClick={() => {
                    navigate("/collegepredictor", {
                      state: { predictorName: "" },
                    });
                  }}
                >
                  PREDICT COLLEGES
                </button>
              </div>
            </div> */}

            {/* <div className="col-lg-8 col-md-12 col-sm-12 predictor">
              <div className="d-flex justify-content-between">
                <span className="predictorPara">
                  HSTES Counselling (Harayana), College Predictor
                </span>
                <button
                  className="predictorBtn"
                  onClick={() => {
                    navigate("/collegepredictor", {
                      state: { predictorName: "" },
                    });
                  }}
                >
                  PREDICT COLLEGES
                </button>
              </div>
            </div> */}

            {/* <div className="col-lg-8 col-md-12 col-sm-12 predictor">
              <div className="d-flex justify-content-between">
                <span className="predictorPara">
                  MAH CET Counselling College Predictor
                </span>
                <button
                  className="predictorBtn"
                  onClick={() => {
                    navigate("/collegepredictor", {
                      state: { predictorName: "" },
                    });
                  }}
                >
                  PREDICT COLLEGES
                </button>
              </div>
            </div> */}

            <div className="col-lg-8 col-md-12 col-sm-12 predictor">
              <div className="d-flex justify-content-between">
                <span className="predictorPara">IIT College Predictor</span>
                <button
                  className="predictorBtn"
                  onClick={() => {
                    navigate("/collegepredictor", {
                      state: { predictorName: "iit_database" },
                    });
                  }}
                >
                  PREDICT COLLEGES
                </button>
              </div>
            </div>
          </div>
        </div>

        {/* <footer> */}
        {/* </footer> */}
      </div>
      <div className="container-fluid" id="footer">
        <div className="row" style={{ backgroundColor: "#41f7cb" }}>
          <div className="col-lg-8 col-md-12 col-sm-12 footer-content">
            <h4>GET ACESSS TO CAREER MARGDARSHAN AI</h4>
            <span>
              GET CHOICE FLLING ADVICE, COMPARE COLLEGE, ANY COLLEGE
              INFORMATION, ETC.
            </span>
          </div>
          <div className="col-lg-4 col-md-12 col-sm-12 d-flex justify-content-center">
            <button
              className="footerBtn"
              onClick={() =>
                window.open("https://ai.careermargdarshan.org/home", "_blank")
              }
            >
              Ask Your Doubts Now !
            </button>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}

export default FilterType;
